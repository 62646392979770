// Imports
// ------
import { observable } from 'mobx';

// Exports
// ------
export const videoState = observable({
	isReady: false,
});

export const menuState = observable({
	isOpen: false,
	heightSize: undefined
});

export const loadingState = observable({
	isFinished: false,
});

export const profileBg = observable({
	isActive: true,
});
