// Imports
// ---------
import styled, { css } from 'styled-components';
import { semantics } from './semantics';
import {
	h1Styles,
	h2Styles,
	h3Styles,
	h4Styles,
	h5Styles,
	h6Styles,
	pStyles,
	spanStyles,
	emStyles,
} from './type';
import { breakpointUp, breakpointDown, breakpointOnly } from './breakpoints';

// --------------
// 1. Semantics
// --------------
export const Section = styled.section`
	${semantics}
`;
export const Div = styled.div`
	${semantics}
`;
export const Main = styled.main`
	${semantics}
`;
export const Nav = styled.nav`
	${semantics}
`;
export const Article = styled.article`
	${semantics}
`;
export const Aside = styled.aside`
	${semantics}
`;
export const Header = styled.header`
	${semantics}
`;
export const Footer = styled.footer`
	${semantics}
`;

// --------------
// 3. Typography
// --------------
export const H1 = styled.h1`
	${h1Styles}
`;
export const H2 = styled.h2`
	${h2Styles}
`;
export const H3 = styled.h3`
	${h3Styles}
`;
export const H4 = styled.h4`
	${h4Styles}
`;
export const H5 = styled.h5`
	${h5Styles}
`;
export const H6 = styled.h6`
	${h6Styles}
`;
export const P = styled.p`
	${pStyles}
`;
export const Em = styled.em`
	${emStyles}
`;
export const Span = styled.span`
	${spanStyles}
`;

// --------------
// 3. Breakpoints
// --------------
export const breakup = breakpointUp;
export const breakdown = breakpointDown;
export const breakonly = breakpointOnly;


// --------------
// 4. Content
// --------------
const sharedBlockStyles = props => css`

	h1, h2, h3, h4, h5, h6 {
		color: ${props.theme.colors.brand.bc5};
		margin: 0 0 1.2rem;

		${breakup.large` margin: 0 0 2.4rem; `}
	}

	h1, h2 {
		${h2Styles}
	}

	h3 {
		${h3Styles}
	}

	h4 {
		${h4Styles}
	}

	h5 {
		${h5Styles}
	}

	h6 {
		${h6Styles}
	}

	ul {
        gap: 2.4rem;

        ${breakup.large`
            gap: 3.6rem;
        `}

        li {
            position: relative;
            padding-left: 3rem;
			margin-bottom: 2.4rem;

            ${breakup.large`
                padding-left: 3.6rem;
            `}

            &:before {
                content: "";
                position: absolute;
                top: 1rem;
                left: 0;
                width: 0.6rem;
                height: 0.6rem;
                background: ${props.theme.colors.brand.bc3};

                ${breakup.large`
                    top: 1.7rem;
                    width: 1.2rem;
                    height: 1.2rem;
                `}
            }
        }
    }

	p {
		${pStyles}
		color: ${props.theme.colors.brand.bc5o6};
		margin: 0 0 2.4rem;

		${breakup.large` margin: 0 0 3.6rem; `}

		span, a, strong, em {
			color: ${props.theme.colors.brand.bc5};
		}

		&:last-child {
			margin: 0;
		}

		strong, a {
			font-weight: ${props => props.theme.font.weight.bold};
		}

		a {
			text-decoration: underline;
			font-weight: 500;
			transition: ${props.theme.easing.ease};

			&:hover {
				opacity: 0.5;
			}
		}
	}
`
export const ContentBlock = styled.div`
	${sharedBlockStyles}
`;

export const ContentBlockStyles = css`
	${sharedBlockStyles}
`;