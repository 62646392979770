// Imports
// ------------
import React from 'react';
import { theme } from '@theme';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const Logo = () => (
    <Jacket width="120" height="46" viewBox="0 0 120 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#logoClip)">
            <path fillRule="evenodd" clipRule="evenodd" d="M72 8H43V0H72V8Z" fill="url(#paint0_linear_11_644)" />
            <path d="M21.9355 27.476C20.0387 26.8635 17.6981 26.4112 14.9161 26.114C12.849 25.8608 11.3523 25.6385 10.4258 25.4499C9.49678 25.2612 8.72775 24.9227 8.11872 24.4369C7.5071 23.951 7.20259 23.2248 7.20259 22.2531C7.20259 20.9455 7.8142 19.9092 9.03742 19.1494C10.2606 18.3897 11.8607 18.0098 13.8426 18.0098C15.8245 18.0098 17.4684 18.4853 18.7742 19.4337C20.08 20.3847 20.8181 21.5967 20.9884 23.0749H27.6284C27.4168 19.7826 26.0361 17.2293 23.4865 15.4126C20.9342 13.5984 17.6155 12.6888 13.5277 12.6888C11.04 12.6888 8.81807 13.11 6.85678 13.9551C4.89549 14.8001 3.35743 15.9915 2.24001 17.5317C1.12259 19.0745 0.565167 20.8344 0.565167 22.8191C0.565167 24.9305 1.12259 26.6076 2.24001 27.8533C3.35743 29.0989 4.83355 30.006 6.66581 30.5771C8.50065 31.1482 10.8284 31.6005 13.6542 31.939C15.7187 32.1922 17.2465 32.4352 18.2374 32.6678C19.2284 32.9003 20.0181 33.2906 20.609 33.8384C21.2 34.3889 21.4942 35.2107 21.4942 36.309C21.4942 37.6192 20.8516 38.6633 19.5665 39.4437C18.2813 40.2267 16.6039 40.6144 14.5394 40.6144C12.2194 40.6144 10.3355 40.0769 8.88001 38.9992C7.42452 37.9216 6.63484 36.5416 6.50839 34.8515H-0.0696716C0.0154897 38.3144 1.39355 41.0253 4.07226 42.9893C6.74839 44.9534 10.2581 45.9328 14.6013 45.9328C17.1716 45.9328 19.4916 45.5219 21.5561 44.6975C23.6207 43.8731 25.2439 42.6921 26.4258 41.1519C27.6052 39.6117 28.1961 37.8285 28.1961 35.7999C28.1961 33.5206 27.6258 31.7167 26.4877 30.3858C25.3497 29.0549 23.8323 28.0858 21.9355 27.4734V27.476Z" fill={theme.colors.brand.bc5} />
            <path d="M41.8529 13.1333H35.0245V45.5555H41.8529V13.1333Z" fill={theme.colors.brand.bc5} />
            <path d="M80.1729 13.1333H73.3445V45.5555H80.1729V13.1333Z" fill={theme.colors.brand.bc5} />
            <path d="M117.892 20.6999C116.521 18.188 114.581 16.2369 112.075 14.8414C109.566 13.4485 106.689 12.7507 103.443 12.7507C100.196 12.7507 97.3187 13.4459 94.8104 14.8414C92.302 16.2343 90.3613 18.188 88.9936 20.6999C87.6233 23.2118 86.9394 26.0933 86.9394 29.3443C86.9394 32.5953 87.6233 35.5414 88.9936 38.0507C90.3639 40.5626 92.302 42.506 94.8104 43.8756C97.3187 45.2479 100.196 45.9327 103.443 45.9327C106.689 45.9327 109.566 45.2479 112.075 43.8756C114.583 42.5034 116.521 40.5626 117.892 38.0507C119.262 35.5388 119.946 32.6367 119.946 29.3443C119.946 26.0519 119.259 23.2118 117.892 20.6999ZM110.366 37.0997C108.617 39.1051 106.31 40.1078 103.443 40.1078C100.576 40.1078 98.2787 39.1051 96.5497 37.0997C94.8207 35.0943 93.9562 32.5074 93.9562 29.3417C93.9562 26.176 94.8207 23.5891 96.5497 21.5837C98.2787 19.5783 100.576 18.5756 103.443 18.5756C106.31 18.5756 108.617 19.5783 110.366 21.5837C112.116 23.5891 112.991 26.176 112.991 29.3417C112.991 32.5074 112.116 35.0943 110.366 37.0997Z" fill={theme.colors.brand.bc5} />
            <path d="M49.8219 13.203H49.7703V45.5555H56.6503V19.5293H67.7161V13.1333H49.8219V13.203Z" fill={theme.colors.brand.bc5} />
            <path d="M43 0.0180664H35V8.01807H43V0.0180664Z" fill={theme.colors.brand.bc2} />
            <path d="M43 0.0180664H35V8.01807H43V0.0180664Z" fill={theme.colors.brand.bc2} />
            <path opacity="0.2" d="M51 0.0180664H43V8.01807H51V0.0180664Z" fill="url(#paint1_linear_11_644)" />
            <path opacity="0.2" d="M72 0.0180664H64V8.01807H72V0.0180664Z" fill="url(#paint2_linear_11_644)" />
            <path d="M80 0H72V8H80V0Z" fill={theme.colors.brand.bc3} />
        </g>
        <defs>
            <linearGradient id="paint0_linear_11_644" x1="43" y1="8" x2="72" y2="8" gradientUnits="userSpaceOnUse">
                <stop stopColor={theme.colors.brand.bc2} />
                <stop offset="1" stopColor={theme.colors.brand.bc3} />
            </linearGradient>
            <linearGradient id="paint1_linear_11_644" x1="43" y1="8" x2="51" y2="8" gradientUnits="userSpaceOnUse">
                <stop />
                <stop offset="1" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_11_644" x1="64" y1="8" x2="72" y2="8" gradientUnits="userSpaceOnUse">
                <stop stopOpacity="0" />
                <stop offset="1" />
            </linearGradient>
            <clipPath id="logoClip">
                <rect width="120" height="46" fill="white" />
            </clipPath>
        </defs>
    </Jacket>
);

export default Logo;