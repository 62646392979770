// **********************************************
// Waffl Grid 1.0
// **********************************************

// Imports
// ------
import offset from './offset';
import ordering from './ordering';
import collapse from './collapse';
import mobilePadding from './mobilePadding';
import visibility from './visibility';
import breakpoints from './breakpoints';
import styled, { css } from 'styled-components';
import { breakup, Div } from '@tackl';
import { pull, pullOnly, push, pushOnly } from './pushPull';

// Exports
// ------
export const Column = styled.div(
	(props) => css`
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		padding-left: calc(${props.theme.grid.gutter.small} / 2);
		padding-right: calc(${props.theme.grid.gutter.small} / 2);

		${breakup.large`
            padding-left: calc(${props.theme.grid.gutter.large} / 2);
            padding-right: calc(${props.theme.grid.gutter.large} / 2);
        `}

		${props.isMaxHeight &&
		`
			height: 100%;
		`}

		/* MobilePadding */
		${mobilePadding}

		/* Ordering */
		${ordering}

		/* Breakpoints */
		${breakpoints}

		/* Push Columns */
		${push}
		${pushOnly}

		/* Pull Columns */
		${pull}
		${pullOnly}

		/* Offset Columns */
		${offset}

		/* Visibility */
		${visibility}
	`
);

export const Row = styled(Div)(
	(props) => css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		flex-flow: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: ${props.theme.grid.maxSize ? props.theme.grid.maxSize : '144rem'};
		margin: 0 auto;

		/* Want to get rid of that pesky margin? */
		${collapse}

		${props.isMaxHeight &&
		css`
			height: 100%;
		`}

    /* Want the grid full width of the screen? */
    ${props.isExpanded &&
		css`
			max-width: 100%;
		`}

    /* Vertically position top content */
    ${props.isTop &&
		css`
			align-items: flex-start;
			height: 100%;
		`}

    /* Vertically Center content */
    ${props.isCenter &&
		css`
			align-items: center;
			height: 100%;
		`}

	${props.isCenterLarge &&
		css`
			${breakup.large`
				align-items: center;
				height: 100%;
			`}
		`}

    /* Align content to bottom */
    ${props.isBottom &&
		css`
			align-items: flex-end;
		`}

    /* Reverse ordering of content */
    ${props.isReversed &&
		css`
			flex-direction: row-reverse;
		`}

    /* Make content equalised */
    ${props.isEqual &&
		css`
			align-items: stretch;

			> ${Column} {
				display: flex;
			}
		`}

    /* Want a vertical grid? */
    ${props.isVertical &&
		css`
			flex-direction: column;
		`}
	`
);
