// Imports
// ------
import React from 'react';
import Header from '@parts/Header';
import Grid from '@parts/Grid';
import { useHeightFix } from '@utils/useHeightFix';
import { useScrollPerformance } from '@utils/useScrollPerformance';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '@theme';

// Styles
// ------
import '@css/global.css';


// Component
// ------
const Layout = ({ children, location }) => {
	useHeightFix();
	useScrollPerformance();

	return (
		<>
			<ThemeProvider theme={theme}>
				<Grid />
				<Header location={location} />
				{children}
			</ThemeProvider>

			<GlobalStyle theme={theme} />
		</>
	);
};


export default Layout;
