// Imports
// ------
import React, { useState } from 'react';
import { Row, Column } from '@waffl';
import { useHotkeys } from 'react-hotkeys-hook';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import { Jacket, Fill } from './styles';

// Component
// ------
function Grid() {
    const [grid, setGrid] = useState(false);
    const [altColor, setAltColor] = useState(true);

    useHotkeys('ctrl+g', () => {
        setGrid((grid) => !grid);
    });
    useHotkeys('ctrl+f', () => {
        setAltColor((altColor) => !altColor);
    });

    const bp = useBreakpoint();

    const columnProps = {
        small: 6,
        medium: 1,
        large: 1,
    };

    return (
        <Jacket showGrid={grid} altColor={altColor}>
            <Row isExpanded isEqual className='row'>
                <Column {...columnProps}>
                    <Fill className='fill' />
                </Column>

                <Column {...columnProps}>
                    <Fill className='fill' />
                </Column>

                {bp.medium && (
                    <>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                        <Column {...columnProps}>
                            <Fill className='fill' />
                        </Column>
                    </>
                )}
            </Row>
        </Jacket>
    );
}

export default Grid;
