// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled.svg(props => css`
    width: 7.875rem;
    height: 3rem;

    ${breakup.large`
        width: 11.739rem;
        height: 4.5rem;
    `}
`);
