// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Header } from '@tackl';
import { Link } from 'gatsby'
import { emStyles, h2Styles, h4Styles } from '@tackl/type';

// Exports
// ------------
export const Jacket = styled(Header)(props => css`
    z-index: 999;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 3.6rem;

    ${breakup.large`
        padding: 8.4rem calc(8.333vw + 1.8rem);
    `}
`);

export const Home = styled(Link)(props => css`
    
`);

export const Menu = styled.nav(props => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6rem;
    }

    li {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            opacity: 0;
            left: 0; right: 0;
            height: 2px;
            background: ${props.theme.colors.brand.bc5};
            transform: scaleX(0);
            transition: ${props.theme.easing.ease};
        }

        &:hover {
            &:after {
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }
`);

export const PageLink = styled(Link)(props => css`
    ${emStyles}
    position: relative;
    color: transparent;
    transition: ${props.theme.easing.ease};
    overflow: hidden;
    padding-bottom: 0.6rem;
    

    &:before,
    &:after {
        content: attr(data-label);
        position: absolute;
        top: 0; left: 0;
        color: ${props.theme.colors.brand.bc5};
        transition: ${props.theme.easing.ease};
    }

    &:before {
        transform: translateY(0%) skewY(0deg);
    }

    &:after {
        transform: translateY(120%) skewY(-12deg);
    }

    &:hover {
        &:before,
        &:after {
            opacity: 0.6;
        }
        &:before {
            transform: translateY(-120%) skewY(12deg);
        }
        &:after {
            transform: translateY(0%) skewY(0deg);
        }
    }
`);

export const CTA = styled(Link)(props => css`
    ${emStyles}
    position: relative;
    color: transparent;
    transition: ${props.theme.easing.ease};
    overflow: hidden;
    border-bottom: 2px solid ${props.theme.colors.brand.bc5};
    padding-bottom: 0.6rem;
    display: none;

    ${breakup.medium`
        display: block;
    `}
    

    &:before,
    &:after {
        content: attr(data-label);
        position: absolute;
        top: 0; left: 0;
        color: ${props.theme.colors.brand.bc5};
        transition: ${props.theme.easing.ease};
    }

    &:before {
        transform: translateY(0%) skewY(0deg);
    }

    &:after {
        transform: translateY(120%) skewY(-6deg);
    }

    &:hover {
        &:before,
        &:after {
            opacity: 0.6;
        }
        &:before {
            transform: translateY(-120%) skewY(6deg);
        }
        &:after {
            transform: translateY(0%) skewY(0deg);
        }
    }
`);


//  Mobile Specific
export const MobileToggle = styled.button(props => css`
    position: relative;
    width: 3.6rem;
    height: 3rem;
    background: transparent;

    ${breakup.large` display: none; `}

    transition: all 1s ${props.theme.easing.bezzy};
    transform: rotate(${props.isOpen ? 225 : 0}deg);


    span {
        position: absolute;
        top: 50%; left: 50%;

        display: block;
        width: 3.6rem;
        height: 2px;
        background: ${props.theme.colors.brand.bc5};
        transition: all 1s ${props.theme.easing.bezzy};


        &:nth-child(1) {
            transform: translate(-50%, -8px) scaleX(0.6);
            transform-origin: center right;

            ${props.isOpen && css`
                transform: translate(-100%, -1.9rem) scaleX(1) rotate(-90deg);
            `}
        }

        &:nth-child(2) {
            transform: translate(-50%, -50%);

            ${props.isOpen && css`
                transform: translate(-50%, -50%);
            `}
        }

        &:nth-child(3) {
            transform: translate(-50%, 6px) scaleX(0.6);
            transform-origin: center left;

            ${props.isOpen && css`
                transform: translate(0%, 1.8rem) scaleX(1) rotate(-90deg);
                opacity: 0;
            `}
        }
    }
`);



export const MobileMenuPageLink = styled(Link)(props => css`
    ${h2Styles}
`);

export const MobileMenuItem = styled.li(props => css`
    opacity: 0;
    transform: translateY(2.4rem);
    transition: all 1s ${props.theme.easing.bezzy};


    ${props.isLast && css`
        position: absolute;
        bottom: 0; left: 0; right: 0;
    
        background: ${props.theme.colors.brand.bc1trans20};

        ${MobileMenuPageLink} {
            ${h4Styles}
            display: flex;
            align-items: center;
            justify-content: center;
            height: 12rem;
            width: 100%;
        }
    `}
`);

export const MobileMenu = styled.ul(props => css`
    position: fixed;
    z-index: 998;
    top: 0; left: 0; right: 0; bottom: 0;
    background: linear-gradient(180deg,
        ${props.theme.colors.brand.bc1} 0%,
        ${props.theme.colors.brand.bc2} 50%,
        ${props.theme.colors.brand.bc3} 100%
    );

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4.8rem;

    pointer-events: ${props.isOpen ? 'all' : 'none'};
    transition: clip-path 1s ${props.theme.easing.bezzy};

    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);

    ${props.isOpen && css`
        clip-path: polygon(-50% 0, 100% 0%, 100% 100%, 0% 100%);

        ${MobileMenuItem} {
            opacity: 1;
            transform: translateY(0rem);
        }
    `}
`);