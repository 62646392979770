// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
export const sharedHeadingStyles = (props) => css`
	font-weight: ${props.theme.font.weight.regular};
	font-family: ${props.theme.font.type.heading};
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
	${sharedHeadingStyles}
	font-size: 6rem;
	line-height: 1.1;
	letter-spacing: -1px;

	${breakpointUp.medium`
		font-size: 9.6rem;
		letter-spacing: -2px;
	`}

	${breakpointUp.xlarge`
		font-size: 12rem;
		letter-spacing: -2px;
	`}
`;

export const h2Styles = css`
	${sharedHeadingStyles}
	font-size: 4.8rem;
	line-height: 1.1;
	letter-spacing: -1px;

    ${breakpointUp.medium`
		font-size: 10.8rem;
		letter-spacing: -2px;
	`}
`;

export const h3Styles = css`
	${sharedHeadingStyles}
	font-size: 3.6rem;
	line-height: 1.1;
	letter-spacing: -0.1rem;

    ${breakpointUp.medium`
		font-size: 9.6rem;
		letter-spacing: -0.2rem;
	`}
`;

export const h4Styles = css`
	${sharedHeadingStyles}
	font-size: 2.6rem;
	line-height: 1.2;


	${breakpointUp.medium`
		font-size: 7.2rem;
		letter-spacing: -0.2rem;
	`}
`;

export const h5Styles = css`
	${sharedHeadingStyles}
	font-size: 2.4rem;
	line-height: 1.2;

	${breakpointUp.medium`
		font-size: 6rem;
		letter-spacing: -0.2rem;
	`}
`;

export const h6Styles = css`
	${sharedHeadingStyles}
	font-size: 2.2rem;
	line-height: 1.32;

	${breakpointUp.medium`
		font-size: 4.8rem;
		letter-spacing: -1px;
	`}
`;

// --------------
// 3. Shared Body Styles
// --------------
export const sharedBodyStyles = (props) => css`
	font-family: ${props.theme.font.type.body};
	font-weight: ${props.theme.font.weight.regular};
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = (props) => css`
	${sharedBodyStyles}
	color: ${props.theme.colors.brand.bc5o6};
	font-size: 2rem;
	line-height: 1.32;

    ${breakpointUp.medium`
		font-size: 3.6rem;
		letter-spacing: -0.5px;
	`}
`;

export const spanStyles = (props) => css`
	${sharedBodyStyles}
	display: block;
	font-weight: ${props.theme.font.weight.reg};
	font-size: 1.8rem;
	line-height: 1.32;

	${breakpointUp.medium` font-size: 2.4rem; `}
`;

export const emStyles = (props) => css`
	${sharedBodyStyles}
	display: block;
	font-style: normal;
	font-size: 1.4rem;
	line-height: 1.6;

	${breakpointUp.medium` font-size: 1.8rem; `}
`;




// For Creating anual sizings
export const type = (props => css`
	font-weight: ${props.theme.font.weight.regular};
	font-family: ${props.theme.font.type.heading};
`)