// Imports
// ------
import React from 'react';

// Component
// ------
export const useHeightFix = () => {
    // NOTE • OnLoad: 100vh Fix
    React.useEffect(() => {
        const setHeightVar = () => {
            let vh = document.documentElement.clientHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        // Init
        setHeightVar();
    }, []);

    return null;
}