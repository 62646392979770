// Imports
// ------------
import React, { useRef, useEffect, useState } from 'react';
import Logo from '@parts/Logo';
import Headroom from 'react-headroom';
import { action } from 'mobx';
import { menuState } from '@states';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------------
import { Jacket, Home, Menu, PageLink, CTA, MobileToggle, MobileMenu, MobileMenuItem, MobileMenuPageLink } from './styles';

// Component
// ------------
const Header = ({ location }) => {
    // NOTE • Active Class Name
    const acn = "active";

    // NOTE • States
    const [menuOpen, setMenuOpen] = useState(false);
    const [isClickable, setIsClickable] = useState(true);

    // NOTE • Breakpoints
    const bp = useBreakpoint();

    // NOTE • refs
    const header = useRef();

    // NOTE • Set Heaqder height globally
    const heightCalc = action(() => {
        menuState.heightSize = header.current.offsetHeight / 10;
    });

    useEffect(() => {
        heightCalc();
    }, []);


    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const handlePage = () => {
        setTimeout(() => {
            setMenuOpen(false);
        }, 250);
    }

    const makeClickless = () => {
        setIsClickable(false);
    }

    const makeClickable = () => {
        setIsClickable(true);
    }

    // Resets pointer events on page change
    useEffect(() => {
        setIsClickable(true);
    }, [location]);

    return (
        <>
            <Headroom
                disableInlineStyles
                downTolerance={10}
                upTolerance={10}
                onUnpin={makeClickless}
                onPin={makeClickable}
                wrapperStyle={{
                    pointerEvents: isClickable ? 'all' : 'none'
                }}
            >
                <Jacket ref={header} menuOpen={menuOpen}>
                    <Home to="/">
                        <Logo />
                    </Home>

                    {bp.medium ? (
                        <Menu>
                            <ul>
                                <li>
                                    <PageLink
                                        to="/"
                                        activeClassName={acn}
                                        data-label="Home">Home</PageLink>
                                </li>
                                <li>
                                    <PageLink
                                        to="/careers"
                                        activeClassName={acn}
                                        data-label="Careers">Careers</PageLink>
                                </li>
                                <li>
                                    <PageLink
                                        to="/news"
                                        activeClassName={acn}
                                        data-label="News">News</PageLink>
                                </li>
                            </ul>
                        </Menu>
                    ) : (
                        <MobileToggle onClick={handleMenu} isOpen={menuOpen}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </MobileToggle>
                    )}

                    <CTA to="/contact/" activeClassName={acn} data-label="Chat to a strategist">Chat to a strategist</CTA>
                </Jacket>
            </Headroom>

            {bp.medium ? null : (
                <MobileMenu isOpen={menuOpen}>
                    <MobileMenuItem>
                        <MobileMenuPageLink
                            onClick={handlePage}
                            to="/"
                            activeClassName={acn}
                        >Home</MobileMenuPageLink>
                    </MobileMenuItem>

                    <MobileMenuItem>
                        <MobileMenuPageLink
                            onClick={handlePage}
                            to="/careers/"
                            activeClassName={acn}
                        >Careers</MobileMenuPageLink>
                    </MobileMenuItem>

                    <MobileMenuItem>
                        <MobileMenuPageLink
                            onClick={handlePage}
                            to="/news/"
                            activeClassName={acn}
                        >News</MobileMenuPageLink>
                    </MobileMenuItem>

                    <MobileMenuItem isLast>
                        <MobileMenuPageLink
                            onClick={handlePage}
                            to="/contact/"
                            activeClassName={acn}
                        >Chat to a specialist</MobileMenuPageLink>
                    </MobileMenuItem>
                </MobileMenu>
            )}
        </>
    );
}

export default Header;